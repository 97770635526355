import Section from "components/Section"
import SectionTitle from "components/SectionTitle"
import React from "react"

const SolutionsSection = ({ banner, content }) => {
  return (
    <Section className={"solutions-section "}>
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row">
          <div
            dangerouslySetInnerHTML={{
              __html: banner,
            }}
            className="order-2 md:order-1"
          ></div>

          <div className="order-1 md:order-2 lg:w-1/2 ml-auto">
            <SectionTitle className="text-2xl">Solutions </SectionTitle>
            <div className="my-12" />

            <div
              dangerouslySetInnerHTML={{
                __html: content,
              }}
              className="space-y-5 md:pl-6 font-light text-sm md:text-lg"
            ></div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default SolutionsSection
