import React, { useState } from "react"

import "./accordion.css"
import { MinusIcon, PlusIcon } from "./Icons"

const Item = ({ question, answer }) => {
  const [showInfo, setShowInfo] = useState(false)

  return (
    <article className="question border-t  border-black py-6">
      <header className="flex justify-between">
        <h4 className="text-xl lg:text-2xl font-medium">{question}</h4>
        <button
          className="btn flex items-center"
          onClick={() => setShowInfo(!showInfo)}
        >
          {showInfo ? (
            <MinusIcon className="w-5 h-5 lg:w-6 lg:h-6" />
          ) : (
            <PlusIcon className="w-5 h-5 lg:w-6 lg:h-6" />
          )}
        </button>
      </header>
      <div
        className={`overflow-hidden transition-all font-light ${
          showInfo ? "open" : "closed"
        }`}
        dangerouslySetInnerHTML={{
          __html: answer,
        }}
      />
    </article>
  )
}

const QuestionsAccordion = ({ questions }) => {
  return (
    <div className="space-y-4">
      {questions?.map((item) => (
        <Item key={item.answer} answer={item.answer} question={item.question} />
      ))}
    </div>
  )
}

export default QuestionsAccordion
