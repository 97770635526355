import Spinner from "assets/icons/Spinner.svg";

export default ({ className, ...props }) => {
  return (
    <img
      {...props}
      className={`w-12 mx-auto h-12 ${className}`}
      src={Spinner}
      alt="spinner"
    />
  );
};
