import { createContext, useContext, useRef, useState } from "react"

const filterContext = createContext()

export const FilterProvider = ({ children }) => {
  const [filters, setFilters] = useState({
    domains: "",
    category: "",
    post_tag: "",
    team: "",
  })

  const postsSectionRef = useRef()

  const updateFilters = (newFilters = {}) => {
    setFilters((prev) => ({
      ...prev,
      ...newFilters,
    }))
  }

  const reset = () =>
    setFilters({
      domains: "",
      category: "",
      post_tag: "",
      team: "",
    })

  return (
    <filterContext.Provider
      value={{
        filters,
        updateFilters,
        reset,
        postsSectionRef,
      }}
    >
      {children}
    </filterContext.Provider>
  )
}

export const useFilterContext = () => useContext(filterContext)
