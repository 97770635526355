import React from "react"
import CustomLink from "./CustomLink"
import { InstaIcon, LinkedInIcon, TwitterIcon } from "./Icons"
import brandLogo from "../assets/icons/logo-dark.svg"

const Footer = () => {
  return (
    <footer>
      <div className="py-8  md:py-12 px-6 container mx-auto">
        <div className="flex justify-between">
          <div className="flex space-x-6 lg:space-x-10 items-center">
            <a
              target={"_blank"}
              href="https://www.linkedin.com/company/ventionteams/"
            >
              <TwitterIcon className=" w-5 h-5 md:w-7 md:h-7" />
            </a>
            <a target={"_blank"} href="https://www.instagram.com/ventionteams/">
              <InstaIcon className=" w-5 h-5 md:w-7 md:h-7" />
            </a>
            <a target={"_blank"} href="https://twitter.com/ventionteams">
              <LinkedInIcon className=" w-5 h-5 md:w-7 md:h-7 " />
            </a>
          </div>
          <CustomLink>
            <img className="w-28 lg:w-40" src={brandLogo} alt="vention" />
          </CustomLink>
        </div>
      </div>
    </footer>
  )
}

export default Footer
