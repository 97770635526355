import { useInfiniteQuery, useQuery } from "react-query"
import { api } from "./api"
import qs from "qs"
import { useSearchParams } from "react-router-dom"
import { useAuthState } from "hooks"

/*
	Filters

	post_tag
	domains 
	category
	team
	f
**/

export const usePosts = (query) => {
  const [params] = useSearchParams()
  const { setAuthState } = useAuthState()

  return useInfiniteQuery(
    ["use-cases", query],
    async ({ pageParam = 1 }) => {
      const res = await api.get(
        `/csc/v2/posts?${qs.stringify({
          ...query,
          token: params.get("token"),
          f: "1",
        })}`,
        {
          headers: {
            "X-CSC-Paged": pageParam,
          },
        }
      )
      const data = res.data
      const totalPages = +res.headers["x-wp-totalpages"]
      return {
        results: data,
        nextPage: (pageParam ?? 1) + 1,
        totalPages,
      }
    },

    {
      getNextPageParam(lastPage) {
        if (lastPage.nextPage <= lastPage.totalPages) return lastPage.nextPage
        return undefined
      },
      retry: 1,
      onError() {
        setAuthState(false)
      },
    }
  )
}

export const usePostById = (id) => {
  const [params] = useSearchParams()
  const { setAuthState } = useAuthState()
  const token = params.get("token")
  return useQuery(
    ["posts", id],
    () => api.get(`/csc/v2/posts/${id}?token=${token}`).then((res) => res.data),
    {
      onError() {
        setAuthState(false)
      },
      retry: 0,
    }
  )
}
