const { useQuery, useQueryClient } = require("react-query")

export const useAuthState = () => {
  const qc = useQueryClient()
  const { data: isLoggedIn } = useQuery("logged-in", () => "", {
    enabled: false,
    initialData: true,
  })

  const setAuthState = (value) => qc.setQueryData("logged-in", value)

  return {
    isLoggedIn,
    setAuthState,
  }
}
