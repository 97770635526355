import DetailHeader from "components/DetailHeader"
import Footer from "components/Footer"
import MultiContentSection from "../sections/MultiContentSection"
import PageSpinner from "components/PageSpinner"
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import AskedQuestionsSection from "sections/AskedQuestionsSection"
import DetailHero from "sections/DetailHeroSection"
import SingleContentSection from "sections/SingleContentSection"
import Solutions from "sections/SolutionsSection"
import StacksSection from "sections/StacksSection"
import TeamsSection from "sections/TeamsSection"
import { usePostById } from "services/post.service"

const sectionsKeys = {
  details_n_team: "details_n_team",
  multicontent: "multicontent",
  single_content: "single_content",
  solutions: "solutions",
  stack_n_architecture: "stack_n_architecture",
  faq: "faq",
}

const RenderSection = ({ section }) => {
  switch (section.type) {
    case sectionsKeys.details_n_team:
      return <TeamsSection {...section.data} />
    case sectionsKeys.stack_n_architecture:
      return <StacksSection {...section.data} />
    case sectionsKeys.solutions:
      return <Solutions {...section.data} />
    case sectionsKeys.single_content:
      return <SingleContentSection {...section.data} />
    case sectionsKeys.multicontent:
      return <MultiContentSection {...section.data} />
    case sectionsKeys.faq:
      return <AskedQuestionsSection {...section.data} />
    default:
      return null
  }
}

export default function DetailPage() {
  const { id } = useParams()
  const { data: post, isLoading } = usePostById(id)

  useEffect(() => {
    if (!isLoading) {
      document.title = `${
        header_section?.nda?.nda
          ? header_section.nda.nda_title
          : post?.data.post_title
      } || Vention`
    }
  }, [post])

  if (isLoading) {
    return <PageSpinner />
  }

  const { header_section, post_title, sections, related_posts } = post?.data

  const mappedSections = sections?.map((sec) => ({
    type: sec.type,
    data: sec,
  }))

  return (
    <div className="detail-page">
      <DetailHeader />
      <DetailHero
        dates={header_section.dates}
        domain={header_section.domain}
        location={header_section.location}
        nda={header_section.nda}
        postTitle={post_title}
        banner={header_section.banner}
        companyLogo={header_section.company_logo}
      />
      {mappedSections?.map((s) => (
        <RenderSection key={s.type} section={s} />
      ))}

      <Footer />
    </div>
  )
}
