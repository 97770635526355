import React, { useState } from "react"
import "./detail-header.css"
import CustomLink from "./CustomLink"
import brandLogo from "../assets/icons/logo-dark.svg"
import backgroundImg from "../assets/icons/header_bg.png"

const DetailHeader = () => {
  return (
    <header className="absolute overflow-hidden bg-white w-full px-10 py-6">
      <CustomLink to="/" className="relative z-10">
        <img src={brandLogo} alt="vention" />
      </CustomLink>
      <img src={backgroundImg} alt="" className="header-background-image" />
    </header>
  )
}

export default DetailHeader
