import React from "react"
import logoBlack from "assets/icons/logo_black.svg"

import arrowDown from "assets/icons/arrow-down.svg"
import Search from "components/Search"
import {
  BrandLogo,
  FacebookIcon,
  InstaIcon,
  LinkedInIcon,
  TwitterIcon,
} from "components/Icons"
import { useStaticHomeData } from "services/home-data.service"

const HomeHeader = () => {
  const { data } = useStaticHomeData()

  return (
    <header
      style={{
        backgroundImage: `url(${data?.frontHeroData?.banner}), linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6))`,
      }}
      id="hero"
      className="p-10 lg:py-20 relative"
    >
      <nav className="z-10">
        <div className="container mx-auto">
          <div className="flex justify-between items-center">
            <div>
              <BrandLogo className="w-28 md:w-52" src={logoBlack} alt="logo" />
            </div>
            <div className="flex space-x-4 md:space-x-8 items-center">
              <a target={"_blank"} href={data?.socialNetworks?.facebook?.href}>
                <FacebookIcon
                  className="md:h-6 h-4 text-white hover:text-primary hover:scale-105 transform transition-all"
                  color="currentColor"
                />
              </a>
              <a target={"_blank"} href={data?.socialNetworks?.twitter?.href}>
                <TwitterIcon
                  className="md:h-6 h-4 text-white hover:text-primary hover:scale-105 transform transition-all"
                  color="currentColor"
                />
              </a>
              <a target={"_blank"} href={data?.socialNetworks?.instagram?.href}>
                <InstaIcon
                  className="md:h-6 h-4 text-white hover:text-primary hover:scale-105 transform transition-all"
                  color="currentColor"
                />
              </a>
              <a target={"_blank"} href={data?.socialNetworks.linkedin?.href}>
                <LinkedInIcon
                  className="md:h-6 h-4 text-white hover:text-primary hover:scale-105 transform transition-all  "
                  color="currentColor"
                />
              </a>
            </div>
          </div>
        </div>
      </nav>

      <div id="intro" className="mt-44">
        <div className="container  mx-auto">
          <div
            className="header-text-container"
            dangerouslySetInnerHTML={{ __html: data?.frontHeroData?.content }}
          ></div>

          <Search />
        </div>
      </div>

      <a
        className="absolute block left-1/2 transform -translate-x-1/2 bottom-12 text-white group"
        href="#posts"
        id="view-more"
      >
        <span className="mb-3 block">View All</span>
        <div className="mx-auto flex justify-center">
          <img
            className="transition-transform transform group-hover:translate-y-2"
            src={arrowDown}
            alt="view-more"
          />
        </div>
      </a>
    </header>
  )
}

{
  /* <h1 className="text-white text-4xl sm:text-5xl md:text-7xl font-semibold">
            Solutions to real world challenges
            </h1>
            
            <p className="text-primary mt-6 md:mt-9 font-semibold text-3xl md:text-5xl">
            Case Studies
          </p> */
}

export default HomeHeader
