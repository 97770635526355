import React from "react"
import arrow from "assets/icons/arrow-right.svg"
import "./card.css"
import CustomLink from "./CustomLink"

//   className="card-image h-48 lg:h-72 object-contain"
const Card = ({ stacks = [], title, description, image, id }) => {
  return (
    <div className="card group">
      <div
        className="logo-container bg-white  flex justify-center"
        dangerouslySetInnerHTML={{
          __html: image,
        }}
      />

      <CustomLink
        to={`/${id}`}
        className=" mb-5 md:mb-9 transition-all group-hover:text-primary text-2xl lg:text-4xl"
      >
        {title}
      </CustomLink>

      <div className="stacks mt-auto flex -space-x-3 mb-5">
        {stacks?.map(
          (stack) =>
            stack?.logo && (
              <span
                className="stack-logo"
                key={stack.slug}
                dangerouslySetInnerHTML={{
                  __html: stack.logo,
                }}
              />
            )
        )}
      </div>

      <p className="text-sm text-mainBlack text-opacity-50">{description}</p>

      <hr className="border-t border-primary bg-primary my-3 mb-5" />

      <CustomLink to={`/${id}`} className="card-link-visible">
        <span className="mr-2 inline text-lg">Show details</span>
        <img
          className="h-2 transition-transform transform"
          src={arrow}
          alt="more"
        />
      </CustomLink>

      <CustomLink to={`/${id}`} className="card-link-hidden">
        <span className="mr-2 text-lg">Show details</span>
        <img
          className="h-2 transition-transform transform"
          src={arrow}
          alt="more"
        />
      </CustomLink>
    </div>
  )
}

export default Card
