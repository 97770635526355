import React from "react"

const Icon = (props) => (
  <svg
    width="12"
    height="10"
    viewBox="0 0 12 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.293056 5.72123C-0.600029 4.68471 0.750382 3.09982 1.64331 4.13634L4.3843 7.30374L10.3502 0.340876C11.2433 -0.701482 12.601 0.883217 11.7079 1.92558L5.06654 9.67267C4.69361 10.1081 4.08874 10.1094 3.71441 9.67485L0.293056 5.72123Z"
      fill="white"
    />
  </svg>
)

const Checkbox = ({ checked, ...props }) => {
  return (
    <div className="inline-block align-middle">
      <input
        type="checkbox"
        className="absolute pointer-events-none invisible"
        {...props}
      />
      <div
        className={`custom-checkbox  ${
          checked ? "bg-mainBlack" : "bg-white"
        } border-[1.5px] border-mainBlack p-[2px] py-[3px]`}
      >
        <Icon className={`${checked ? "visible" : "invisible"}`} />
      </div>
    </div>
  )
}

export default Checkbox
