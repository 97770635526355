import { useEffect, useRef, useState } from "react"
import { useInfiniteQuery } from "react-query"

export function useElementOnScreen({
  threshold = 0.1,
  root = null,
  rootMargin = "50%",
  enabled = false,
  onScreen = () => null,
}) {
  const targetRef = useRef(null)

  useEffect(() => {
    const node = targetRef?.current // DOM Ref
    if (!enabled || !node) return

    const observer = new IntersectionObserver(
      (entries) =>
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            onScreen()
          }
        }),
      {
        threshold,
        root,
        rootMargin,
      }
    )

    observer.observe(node)

    // eslint-disable-next-line consistent-return
    return () => {
      observer.unobserve(node)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetRef?.current, enabled])

  return targetRef
}

export const useDebounce = (value, delay = 500) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}
