import Section from "components/Section"
import React from "react"
import "./team.css"

const TeamsSection = ({ content, team = [] }) => {
  return (
    <Section className={"bg-[#f4f4f4] team-section"}>
      <div className="container mx-auto">
        {!content && (
          <h2
            className={
              "font-medium  text-center md:text-left  mb-14 lg:ml-20 text-2xl  md:text-3xl lg:text-4xl"
            }
          >
            Our team
          </h2>
        )}
        <div className="team-section-content flex flex-col gap-14  md:flex-row ">
          {content && (
            <div
              className="md:w-[70%]"
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            ></div>
          )}

          <div
            className={`flex  flex-wrap ${
              content
                ? "md:w-1/2 w-full gap-y-8 "
                : "w-full gap-y-8 md:gap-y-20"
            }`}
          >
            {team?.map((t) => (
              <div
                className={`flex w-1/2 ${
                  content ? "" : "md:w-1/3"
                }   h-max flex-col gap-5`}
                key={t.stack}
              >
                <span className="block text-3xl text-center sm:text-5xl md:text-6xl lg:text-8xl text-primary">
                  {t.number}
                </span>
                <span className="text-center font-light text-sm md:text-lg  ">
                  {t.stack}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Section>
  )
}

// const data = [
//   {
//     name: "Python Enginers",
//     number: 10,
//   },
//   {
//     name: "React.js Enginers",
//     number: 6,
//   },
//   {
//     name: "DevOps",
//     number: 1,
//   },
//   {
//     name: "QA Tester",
//     number: 5,
//   },
// ]

{
  /* <h2 className="text-xl md:text-4xl ">Current Phase:</h2>
            <br />
            <p className="font-light">
              Lorem, ipsum dolor sit amet consectetur adipisicing
              adipisicingadipisicing adipisicing Lorem, ipsum dolor sit amet
              consectetur adipisicing adipisicingadipisicing adipisicing
            </p>

            <div className="my-8" />

            <h2 className="text-xl">Current & future responsibilities:</h2>

            <ul className="py-3 pl-6 list-disc space-y-2 font-light text-[16px]">
              <li>Fine lsopfjs sdiofjnban sosnrin sidnsinbf sidnf,</li>
              <li>Fine lsopfjs sdiofjnban sosnrin sidnsinbf sidnf,</li>
              <li>Fine lsopfjs sdiofjnban sosnrin sidnsinbf sidnf,</li>
              <li>Fine lsopfjs sdiofjnban sosnrin sidnsinbf sidnf,</li>
            </ul>

            <div className="my-8" />

            <h2 className="text-xl">Product development methodology</h2>
            <br />
            <p className="font-light">Scrum + extreme programming</p> */
}

export default TeamsSection
