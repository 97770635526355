import React from "react";
import { useElementOnScreen } from "utils";
import Spinner from "./Spinner";

// interface Props {
//   hasMore: boolean | undefined;
//   loadMore: () => void;
//   loading?: boolean;
// }

const InfiniteLoader = (props) => {
  const targetRef = useElementOnScreen({
    enabled: !!props.hasMore,
    onScreen: () => {
      props.loadMore();
    },
    rootMargin: "50%",
    threshold: 0.5,
  });

  return (
    <>
      {props.children}
      {props.loading ? (
        <div className="w-full grid place-items-center">
          <Spinner className="" />
        </div>
      ) : null}
      <span
        aria-label="bottom"
        className="w-full h-1 mt-auto invisible"
        ref={targetRef}
      />
    </>
  );
};

InfiniteLoader.defaultProps = {
  loading: false,
};

export default InfiniteLoader;
