import React from "react"
import Section from "components/Section"

const DetailHeroSection = ({
  dates,
  domain,
  location,
  nda,
  postTitle,
  banner,
  companyLogo,
}) => {
  return (
    <Section className="pb-0 pt-32 py-32 sm:pt-56 md:pt-40 md:pb-0 lg:mb-0">
      <div className="container relative mx-auto">
        <div className="flex flex-col md:flex-row justify-between">
          <div className="sm:w-1/2 my-auto">
            <div className="flex flex-col md:flex-row flex-wrap lg:flex-nowrap items-center">
              <h1 className="text-4xl  md:text-6xl lg:text-6xl text-mainBlack">
                {nda?.nda ? nda?.nda_title : postTitle}
              </h1>

              {!nda?.nda && (
                <div
                  className="w-2/3 self-center md:w-1/2 lg:min-w-[unset] md:min-w-[150px]"
                  dangerouslySetInnerHTML={{
                    __html: companyLogo,
                  }}
                ></div>
              )}
            </div>

            {nda.nda && (
              <p className="lg:text-2xl mt-6 md:mt-12 font-normal tracking-tight">
                This information is subject to NDA
              </p>
            )}

            <div className="mt-10 md:mt-24 w-full md:w-10/12 flex gap-5">
              <div className="py-2 w-1/3">
                <hr className="border-[1px] md:border-1 bg-primary2 max-w-[175px] mb-2 border-primary2" />
                <h3 className="text-lg font-medium md:text-2xl leading-9">
                  {domain.title}
                </h3>
                <span className="mt-2 md:mt-4 block md:text-lg text-sm leading-7 font-light">
                  {domain.value}
                </span>
              </div>

              <div className="py-2 w-1/3">
                <hr className="border-[1px] md:border-1 bg-primary2 max-w-[175px] mb-2 border-primary2" />
                <h3 className="text-lg font-medium md:text-2xl leading-9">
                  {dates.title}
                </h3>
                <span className="mt-2 md:mt-4 block md:text-lg text-sm leading-7 font-light">
                  {dates.start}
                </span>
              </div>

              <div className="py-2 w-1/3">
                <hr className="border-[1px] md:border-1 bg-primary2 max-w-[175px] mb-2 border-primary2" />
                <h3 className="text-lg font-medium md:text-2xl leading-9">
                  {location.title}
                </h3>
                <span className="mt-2 md:mt-4 block md:text-lg text-sm leading-7 font-light">
                  {location.value}
                </span>
              </div>
            </div>
          </div>

          <div
            className="w-full hue-rotate-15 banner-img my-auto mx-auto mt-12 sm:mt-0  md:w-1/2"
            dangerouslySetInnerHTML={{
              __html: banner,
            }}
          ></div>
        </div>
      </div>
    </Section>
  )
}

export default DetailHeroSection
