const { useQuery } = require("react-query")
const { api } = require("./api")

const fetchHomePageData = async () => {
  const [frontHeroData, socialNetworks] = await Promise.all([
    api("/csc/v2/options/front_hero").then((res) => res.data),
    api("/csc/v2/options/social_networks").then((res) => res.data),
  ])

  return {
    frontHeroData,
    socialNetworks,
  }
}

export const useStaticHomeData = () =>
  useQuery("static-content-home", fetchHomePageData, {
    staleTime: 10000000,
  })
