import React from "react"

const Section = ({ id, children, className }) => {
  return (
    <section
      id={id}
      className={`px-6 py-10 md:py-14 lg:py-24 md:px-10 ${className}`}
    >
      {children}
    </section>
  )
}

export default Section
