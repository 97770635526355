import React from "react"
import Section from "components/Section"

const MultiContentSection = ({ content }) => {
  return (
    <Section className={"multi-content pt-0 md:pt-0 lg:pt-0"}>
      <div className="container mx-auto">
        <div className="flex flex-col gap-5 md:gap-10">
          {content?.map((c) => {
            return (
              <div
                key={c.title}
                className="flex flex-col gap-3 md:flex-row flex-wrap"
              >
                <h3 className="text-2xl font-normal w-1/3 md:text-4xl">
                  {c.title}
                </h3>
                <div
                  className="multi-content-paragraph-container md:w-[60%]"
                  dangerouslySetInnerHTML={{
                    __html: c.content,
                  }}
                />
              </div>
            )
          })}
        </div>
      </div>
    </Section>
  )
}

export default MultiContentSection
