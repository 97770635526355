import React, { useEffect } from "react"
import "./index.css"
import "./App.css"
import HomePage from "pages/home/Home.page"
import DetailPage from "pages/DetailPage"
import { Route, Routes, useLocation } from "react-router-dom"
import NotFoundPage from "pages/NotFoundPage"
import { useAuthState } from "hooks"

// "homepage": "/app/themes/case-study"

function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

export default function App() {
  const { isLoggedIn } = useAuthState()

  return (
    <>
      <Routes>
        {isLoggedIn ? (
          <>
            <Route path="/" element={<HomePage />} />
            <Route path="/:id" element={<DetailPage />} />
          </>
        ) : (
          <Route path="*" element={<NotFoundPage />} />
        )}
      </Routes>
      <ScrollToTop />
    </>
  )
}
