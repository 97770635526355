import QuestionsAccordion from "components/QuestionsAccordion"
import Section from "components/Section"
import SectionTitle from "components/SectionTitle"
import React from "react"

const AskedQuestionsSection = ({ content }) => {
  return (
    <Section className={"py-20 pb-3 lg:py-28 bg-[#F2F4F4]"}>
      <div className="container mx-auto">
        <div className="flex flex-wrap">
          <SectionTitle className={"w-full mb-20  lg:w-[40%]"}>
            Frequently asked questions about the project
          </SectionTitle>
          <div className="lg:w-[50%]">
            <QuestionsAccordion questions={content} />
          </div>
        </div>
      </div>
    </Section>
  )
}

export default AskedQuestionsSection
