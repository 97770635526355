import { FilterProvider } from "context/filter.context"
import { useEffect } from "react"
import HomeHeader from "sections/home-header/HomeHeader"
import CaseStudies from "../../sections/CaseStudiesSection"

const Home = () => {
  useEffect(() => {
    document.title = "Case studies || Vention"
  }, [])

  return (
    <FilterProvider>
      <HomeHeader />
      <CaseStudies />
    </FilterProvider>
  )
}

export default Home
