import qs from "qs"
import { useQuery } from "react-query"
import { useSearchParams } from "react-router-dom"
import { api } from "./api"

export const useSearchPosts = (term) => {
  const [params] = useSearchParams()
  const token = params.get("token")
  return useQuery(
    ["search", term],
    () => {
      return api
        .get(
          `/csc/v2/search/cv?${qs.stringify({
            search: term,
            token,
          })}`
        )
        .then((res) => {
          return res.data?.map((p) => ({
            id: p.id,
            title: p.post_title,
          }))
        })
    },
    {
      enabled: !!term,
      staleTime: 10000,
      retryOnMount: false,
    }
  )
}
