import axios from "axios"

const baseURL = document.querySelector('meta[name="rest-base"]')?.content

export const api = axios.create({
  baseURL: baseURL,
  headers: {
    "X-WP-Theme": "CS",
  },
})
