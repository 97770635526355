import { useQuery } from "react-query"
import { useSearchParams } from "react-router-dom"

const { api } = require("./api")

export const useFilters = () => {
  const [params] = useSearchParams()
  const token = params.get("token")
  return useQuery("filters", () =>
    api.get(`/csc/v2/sorting?token=${token}`).then((res) => {
      return res.data
    })
  )
}
