import Section from "components/Section"
import SectionTitle from "components/SectionTitle"
// import { djangoIcon, reactIcon, rubyIcon, vueIcon } from "data"
import React from "react"
// import image from "assets/images/stacks-image.svg"

const StacksSection = ({
  banner,
  source_links = [],
  stack_groups = [],
  stack_list = {},
  title,
}) => {
  return (
    <Section className={" "}>
      <div className="container mx-auto">
        <SectionTitle className={"max-w-max"}>
          {title}
          <hr className="border border-gray-100 mt-6" />
        </SectionTitle>
        <div className="my-6"></div>
        <div className="flex mb-5 gap-3 flex-wrap">
          {Object.values(stack_list)?.map((st) =>
            st?.logo ? (
              <span
                key={st.logo + Date.now()}
                dangerouslySetInnerHTML={{
                  __html: st.logo,
                }}
                className="w-12 h-12"
              />
            ) : null
          )}
        </div>

        <div className="flex justify-between flex-col lg:flex-row">
          <div className="lg:w-1/2  pt-10 space-y-10">
            {stack_groups?.map((g) => (
              <div key={g.group} className="space-y-4">
                <h4 className="text-primary2 text-lg md:text-xl">{g.group}</h4>
                <p className="pl-auto font-light text-sm md:text-xl relative  pl-6">
                  {g.values}
                  <span className="absolute bg-black left-1 h-1 w-1 top-3 rounded-full"></span>
                </p>
              </div>
            ))}
          </div>

          <div
            dangerouslySetInnerHTML={{
              __html: banner,
            }}
            className="mt-10 lg:md-0 hue-rotate-15"
          ></div>
        </div>

        <div className="flex justify-end gap-4 mt-8  md:mt-12">
          {source_links?.map((link) => (
            <a
              key={link.url}
              className="text-primary2   uppercase font-poppins underline underline-offset-1 text-sm md:text-lg md:text-xl block"
              href={link.url}
              target={link.target ?? "_blank"}
            >
              {link.title}
            </a>
          ))}
        </div>
      </div>
    </Section>
  )
}

export default StacksSection
