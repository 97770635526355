import React, { useState } from "react"
import crossIcon from "assets/icons/cross.svg"
import Select from "./Select"
import "./filter.css"
import { useFilters } from "services/filter.service"
import { useFilterContext } from "context/filter.context"

const transFormTerms = (terms) => {
  if (!terms) return []
  if (Array.isArray(terms)) {
    return terms.map((t) => ({
      value: t.value,
      label: t.name,
    }))
  }

  return Object.values(terms).map((t) => ({
    value: t.value,
    label: t.name,
  }))
}

const initialState = {
  domains: [],
  category: null,
  techStack: [],
  teamSize: null,
}

const Filter = ({ isOpen, onClose }) => {
  const { data } = useFilters()
  const { updateFilters, postsSectionRef } = useFilterContext()

  const [formState, setFormState] = useState(initialState)
  const handleSubmit = (e) => {
    e.preventDefault()

    const { category, teamSize, domains, techStack } = formState

    const domainsString = JSON.stringify(domains.map((v) => v.value))

    const teckStackString = JSON.stringify(
      formState.techStack.map((v) => v.value)
    )

    updateFilters({
      domains: domains.length === 0 ? undefined : domainsString,
      category: category ? category.value : undefined,
      post_tag: techStack?.length === 0 ? undefined : teckStackString,
      team: teamSize ? formState.teamSize.value : undefined,
    })
    // onClose()
    postsSectionRef.current.scrollIntoView()
  }
  const handleChange = (value, option) => {
    setFormState({
      ...formState,
      [option.name]: value,
    })
  }

  const getValue = (name) => formState[name]

  const reset = () => {
    setFormState({
      domains: [],
      category: null,
      techStack: [],
      teamSize: null,
    })
    updateFilters({
      domains: "",
      category: "",
      post_tag: "",
      team: "",
    })
    onClose()
  }

  return (
    <div
      id="filter-container"
      className={`filter-container  ${
        isOpen ? "opacity-1" : "opacity-0 pointer-events-none"
      }`}
    >
      <form
        onSubmit={handleSubmit}
        className="flex mt-11 sm:mt-0  justify-between flex-wrap gap-12  md:gap-4"
      >
        <Select
          onChange={handleChange}
          name="domains"
          label={data?.domains?.label}
          placeholder={data?.domains?.default}
          value={formState.domains}
          isMulti
          options={transFormTerms(data?.domains?.terms)}
        />
        <Select
          onChange={handleChange}
          name="category"
          value={getValue("category")}
          label={data?.category?.default}
          options={transFormTerms(data?.category?.terms)}
          placeholder={data?.category?.default}
        />
        <Select
          onChange={handleChange}
          value={getValue("techStack")}
          name="techStack"
          label={data?.post_tag?.label}
          placeholder={data?.post_tag?.default}
          isMulti
          options={transFormTerms(data?.post_tag?.terms)}
        />
        <Select
          onChange={handleChange}
          value={getValue("teamSize")}
          name="teamSize"
          label={data?.team?.label}
          placeholder={data?.team?.default}
          options={transFormTerms(data?.team?.terms)}
        />

        <div className="flex  mx-auto lg:justify-between gap-2 items-center justify-center">
          <button
            type="submit"
            className="mt-5 hover:bg-primary2 flex-grow basis-[100px] text-white border-none active:outline-none bg-primary active:bg-red-600 md:mt-auto px-14 py-4 text-center"
          >
            Apply
          </button>

          <button
            onClick={reset}
            type="button"
            className="grid place-items-center h-14 w-14 bg-black mt-auto"
          >
            <img src={crossIcon} alt="close" />
          </button>
        </div>
      </form>
    </div>
  )
}

export default Filter
