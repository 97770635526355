import React from "react"
import Spinner from "./Spinner"

const PageSpinner = () => {
  return (
    <div className="z-20 bg-white absolute top-0 right-0 bottom-0 left-0 grid place-items-center">
      <Spinner />
    </div>
  )
}

export default PageSpinner
