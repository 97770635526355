import React from "react"
import Card from "components/Card"
import InfiniteLoader from "components/InfiniteLoader"
import { usePosts } from "services/post.service"
import { useFilterContext } from "context/filter.context"
import PageSpinner from "components/PageSpinner"
import { useSearchParams } from "react-router-dom"

const CaseStudiesSection = () => {
  const { filters, postsSectionRef } = useFilterContext()

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } =
    usePosts(filters)

  if (isLoading) {
    return <PageSpinner />
  }

  return (
    <section
      ref={postsSectionRef}
      id="posts"
      className={"py-20 px-6 md:px-10 bg-[#F2F4F4]"}
    >
      <div className="container mx-auto">
        <h2 className="text-primary text-center text-4xl lg:text-6xl font-semibold">
          Case Studies
        </h2>

        <div className="cards  gap-5 relative md:gap-3 xl:gap-14 2xl:gap-20 mt-24  mx-auto flex flex-wrap">
          <InfiniteLoader
            loadMore={fetchNextPage}
            loading={isFetchingNextPage || isLoading}
            hasMore={hasNextPage}
          >
            {data?.pages?.map((p) => {
              return (
                p.results &&
                p?.results?.map((r) => (
                  <Card
                    key={r.id}
                    description={r.data?.excerpt}
                    title={r.data?.post_title}
                    stacks={r.data?.stack}
                    image={r.data?.post_thumbnail}
                    id={r.id}
                  />
                ))
              )
            })}
          </InfiniteLoader>
        </div>
      </div>
    </section>
  )
}

export default CaseStudiesSection
