import React from "react"
import image404 from "assets/images/404.jpg"
const NotFoundPage = () => {
  return (
    <div className="h-screen flex justify-center flex-col">
      <div className="max-w-5xl w-full mx-auto ">
        <img src={image404} alt="not found" />
      </div>

      {/* <h1 className="font-medium text-primary2 text-2xl md:text-4xl text-center">
        Not Found!
      </h1> */}
    </div>
  )
}

export default NotFoundPage
