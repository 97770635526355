import Filter from "./Filter"
import filterIcon from "assets/icons/filter.svg"
import searchIcon from "assets/icons/search.svg"
import React, { useState, useEffect } from "react"
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox"
import "@reach/combobox/styles.css"

import "./search.css"
import { useContext } from "react"
import { useFilterContext } from "context/filter.context"
import { useSearchPosts } from "services/search.service"
import { useDebounce } from "utils"
import { useNavigate, useSearchParams } from "react-router-dom"
import CustomLink from "./CustomLink"

const AutoComplete = ({ isExpanded, closeFilter, toggelFilter }) => {
  const [searchTerm, setSearchTerm] = React.useState("")
  const dterm = useDebounce(searchTerm, 200)
  const { data, isLoading } = useSearchPosts(dterm)

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value)
  }

  useEffect(() => {
    if (isExpanded) {
      closeFilter()
    }
    return () => {}
  }, [isExpanded])

  return (
    <>
      <img
        className="absolute w-4 md:w-6 left-4 top-1/2 transform -translate-y-[50%]"
        src={searchIcon}
        alt="search"
      />
      <button
        onClick={toggelFilter}
        id="filter-button "
        className={`${
          isExpanded ? "opacity-0 pointer-events-none" : ""
        } transition-opacity  px-3 md:px-4 grid place-items-center  h-full right-0 absolute bg-white focus:outline-none`}
      >
        <img src={filterIcon} alt="filter" />
      </button>
      <ComboboxInput
        onChange={handleSearchTermChange}
        placeholder="Search by name"
        className={`w-full font-normal bg-transparent border-white border-2 ${
          isExpanded ? "border-b-0 bg-bgWhite20" : ""
        } focus:outline-none text-white py-2 md:py-4 px-9 md:px-14 text-sm md:text-lg placeholder-opacity-1`}
      />
      <ComboboxPopover
        portal={false}
        className="max-h-[300px] absolute w-full z-10 overflow-y-auto"
      >
        <ComboboxList>
          {data?.map((p) => (
            <ComboboxOption key={p.id} title={p.title} value={p.id}>
              {p.title}
            </ComboboxOption>
          ))}
        </ComboboxList>
      </ComboboxPopover>
    </>
  )
}

const Search = () => {
  const [isOpen, setOpen] = useState(false)

  const navigate = useNavigate()
  const [params] = useSearchParams()
  const token = params.get("token")

  return (
    <div id="search" className="search mt-20 flex sm:relative">
      <Combobox
        onSelect={(value) => {
          navigate(`/${value}?token=${token}`)
        }}
        className="w-full group relative"
      >
        {({ isExpanded }) => {
          return (
            <AutoComplete
              isExpanded={isExpanded}
              closeFilter={() => setOpen(false)}
              toggelFilter={() => setOpen(!isOpen)}
            />
          )
        }}
      </Combobox>

      <Filter isOpen={isOpen} onClose={() => setOpen(false)} />
    </div>
  )
}

export default Search
