import React from "react"
import { Link, useSearchParams } from "react-router-dom"

const CustomLink = ({ to = "/", className, children, ...props }) => {
  const [params] = useSearchParams()
  const token = params.get("token")
  return (
    <Link {...props} className={className} to={`${to}?token=${token}`}>
      {children}
    </Link>
  )
}

export default CustomLink
