import React from "react"
import ReactSelect, { components } from "react-select"
import "./select.css"
import Checkbox from "./Checkbox"

const customStyles = {
  menu: (provided) => ({
    ...provided,
    marginTop: 0,
    borderRadius: 0,
  }),
  option: (provided) => {
    return Object.assign(provided, {
      backgroundColor: "white",
      color: "#131315",
      paddingLeft: "12px",
    })
  },
  valueContainer: (provided, s) => {
    return {
      ...provided,
      paddingLeft: 0,
      position: "relative",
      maxHeight: 36,
      overflowY: s.isMulti ? "auto" : "unset",
    }
  },
  dropdownIndicator: (b, s) => {
    return Object.assign(b, {
      transition: "all .3s ease",
      transform: `${s.selectProps.menuIsOpen ? "rotate(180deg)" : ""} `,
      color: "#131315",
    })
  },
}

const Option = (props) => {
  return (
    <components.Option {...props} className="flex  items-end">
      {props.isMulti && (
        <Checkbox checked={props.isSelected} onChange={() => null} />
      )}
      <label className={props.isMulti ? "ml-4 align-middle-bottom" : ""}>
        {props.label}
      </label>
    </components.Option>
  )
}

const Select = ({ onChange, options, label, placeholder, value, ...props }) => {
  return (
    <div className="select-container relative basis-52 flex-grow flex flex-col">
      <label className="text-mainBlack sm:text-white mb-2">{label}</label>
      <ReactSelect
        styles={customStyles}
        className="custom-select relative"
        options={options}
        classNamePrefix="custom-select"
        hideSelectedOptions={false}
        placeholder={placeholder}
        closeMenuOnSelect={!props.isMulti}
        value={value}
        components={{
          Option,
        }}
        onChange={onChange}
        {...props}
      />
    </div>
  )
}

export default Select
