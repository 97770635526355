import Section from "components/Section"
import React from "react"

{
  /* <SectionTitle>Results</SectionTitle> */
}
const SingleContentSection = ({ content }) => {
  return (
    <Section className={"bg-primary single-content px-6"} id="single-content">
      <div className="container text-white mx-auto">
        <div className="my-10" />
        <div
          dangerouslySetInnerHTML={{
            __html: content,
          }}
          className="text-sm font-light md:text-xl"
        ></div>
      </div>
    </Section>
  )
}

export default SingleContentSection
